<template>
  <v-layout column fill-height class="text-only-mission">
    <!-- Mission question -->
    <v-layout column>
      <template v-if="instructionsPhotoUrl">
        <v-flex v-if="serialized" d-flex xs2>
          <ResizableText
            :key="serialized"
            :message="serialized"
            style="margin-top: 4px; margin-bottom: 4px"
          />
        </v-flex>
        <v-flex
          grow
          d-flex
          v-if="instructionsPhotoUrl"
          class="text-only-mission__image"
          :style="`background-image: url('${instructionsPhotoUrl}')`"
        />
      </template>

      <v-flex v-else d-flex>
        <ResizableText :key="serialized" :message="serialized" />
      </v-flex>
    </v-layout>
    <v-flex v-if="isLinkMission && showLink">
      <h1>
        <span v-if="!!showWinningTeam">Only Winning Team: </span>
        <a :href="compositeLink" :target="targetLink"
          >{{ mission.linkLabel || mission.answer }}
        </a>
      </h1>
    </v-flex>
  </v-layout>
</template>

<script>
import ResizableText from "@/components/GroupTeams/Common/Games/ResizableText.vue"
import { mapGetters } from "vuex"
import Mission from "@shared/enums/Mission"
import Team from "@shared/Team"

const REGEX_EXTRACT_PATTERN = /<img.*?src="(.*?)"/
const REGEX_REPLACE_PATTERN = /<img[^>]*>/g

export default {
  name: "TextOnly",
  components: {
    ResizableText
  },
  props: {
    mission: Object
  },
  computed: {
    ...mapGetters("group", ["isViewerHostLike"]),
    ...mapGetters({
      teams: "chats"
    }),
    ...mapGetters("auth", ["user", "isHost"]),
    instructions() {
      return this.mission?.instructions?.trim().replace("/n", "<br/>")
    },
    extractedUrl() {
      return REGEX_EXTRACT_PATTERN.exec(this.instructions)?.[1]
    },
    instructionsPhotoUrl() {
      const uri = this.mission?.instructionsPhoto ?? this.extractedUrl
      if (uri == null) return null
      if (typeof uri !== "string") return null
      return decodeURI(uri)
    },
    serialized() {
      return this.instructions?.replace(REGEX_REPLACE_PATTERN, "")
    },
    isLinkMission() {
      return this.mission?.behavior === Mission.Link
    },
    compositeLink() {
      if (this.mission.sendUserParams) {
        return (
          this.mission?.answer +
          "?anon=1&onboarding=0&FN=" +
          this.user?.firstname +
          "&LN=" +
          this.user?.lastname +
          "&image=" +
          this.user?.image
        )
      } else {
        return this.mission?.answer
      }
    },
    targetLink() {
      return !this.mission?.sendUserParams ? "_blank" : ""
    },
    showWinningTeam() {
      return this.mission?.showWinningTeam
    },
    showLink() {
      const showWinningTeam = this.showWinningTeam
      return (
        (this.isLinkMission &&
          // let's not execute isWinningTeam is we don't have to
          (!showWinningTeam || (showWinningTeam && this.isWinningTeam))) ||
        this.isHost
      )
    },
    isWinningTeam() {
      const [winningTeam] = Team.normalize(this.teams).sort(Team.sortByScore)
      return winningTeam && this.user?.teamID === winningTeam.id
    }
  }
}
</script>

<style lang="scss">
.text-only-mission {
  &__image {
    position: relative;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    transform: translateZ(0);
    margin-bottom: 8px;
    margin-top: 8px;
  }
  .mission-instructions {
    color: $color-black;
    padding: 10px;
    font-size: 22px;
  }
}
</style>
