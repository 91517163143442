var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticClass: "text-only-mission",
      attrs: { column: "", "fill-height": "" },
    },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _vm.instructionsPhotoUrl
            ? [
                _vm.serialized
                  ? _c(
                      "v-flex",
                      { attrs: { "d-flex": "", xs2: "" } },
                      [
                        _c("ResizableText", {
                          key: _vm.serialized,
                          staticStyle: {
                            "margin-top": "4px",
                            "margin-bottom": "4px",
                          },
                          attrs: { message: _vm.serialized },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.instructionsPhotoUrl
                  ? _c("v-flex", {
                      staticClass: "text-only-mission__image",
                      style:
                        "background-image: url('" +
                        _vm.instructionsPhotoUrl +
                        "')",
                      attrs: { grow: "", "d-flex": "" },
                    })
                  : _vm._e(),
              ]
            : _c(
                "v-flex",
                { attrs: { "d-flex": "" } },
                [
                  _c("ResizableText", {
                    key: _vm.serialized,
                    attrs: { message: _vm.serialized },
                  }),
                ],
                1
              ),
        ],
        2
      ),
      _vm.isLinkMission && _vm.showLink
        ? _c("v-flex", [
            _c("h1", [
              !!_vm.showWinningTeam
                ? _c("span", [_vm._v("Only Winning Team: ")])
                : _vm._e(),
              _c(
                "a",
                { attrs: { href: _vm.compositeLink, target: _vm.targetLink } },
                [
                  _vm._v(
                    _vm._s(_vm.mission.linkLabel || _vm.mission.answer) + " "
                  ),
                ]
              ),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }